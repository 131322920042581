import type { Primitive } from 'type-fest'
import { unrefElement } from '@vueuse/core'
import { computed, defineComponent, type Directive, h, onBeforeUnmount, onUnmounted, ref, type ShallowReactive, shallowReactive } from 'vue'
import { animateDOM, uuid } from '../helpers'

/** Returns an UID for the component to use */
export function useUID(prefix = '') {
  const key = '$vue-components-uid'
  const store: { [key]: number } = globalThis as any

  // Add key to window
  if (!store[key])
    store[key] = 0

  // Increment key
  const id = String(++store[key])

  return prefix ? `${prefix}-${id}` : id
}

/** Stores shallow reactive state in memory */
export function useMemoryState<T extends object>(key: string, data: T, {
  /** Should proxy state be delete when component is unmounted */
  dispose = true
} = {}) {
  const store = (window as any)['__$v-reactive-memory-stores$__'] ||= {}
  const state = store[key] ||= shallowReactive(data) as ShallowReactive<T>

  if (dispose) {
    onUnmounted(() => delete store[key])
  }

  return state
}

export function useViewTransition() {
  const nodes: { [key: string]: HTMLElement } = {}

  const vWatchTransition: Directive<HTMLElement, string> = {
    mounted(node, binding) {
      const key = getKey(binding.value)
      nodes[node.dataset.vBindName = key] = node
    },

    unmounted(node) {
      const key = node.dataset.vBindName
      if (key)
        delete nodes[key]
    }
  }

  function getKey(key?: string) {
    return key ?? uuid()
  }

  function setState(active: boolean) {
    Object.entries(nodes).forEach(([name, node]) => {
      node.style.viewTransitionName = active ? name : ''
    })
  }

  function animate(fn: VoidFunction) {
    setState(true)

    const result = animateDOM(fn)

    result.finished.then(() => {
      setState(false)
    })

    return result
  }

  return { vWatchTransition, animate }
}

export function useAnimatedRef<T extends Primitive>(initialValue: T) {
  const state = ref(initialValue)

  return computed({
    get: () => state.value,
    set: (value: T) => animateDOM(() => state.value = value)
  })
}

/**
 * Easily teleport a source component to a target destination
 * @example Usage
 * ```vue
 * <script setup lang="ts">
 * const [sourceRef, TargetPortal] = useTeleport()
 * const isActive = ref(false)
 * </script>
 *
 * <template>
 *  <div>
 *    <!-- Source element -->
 *    <MyComponent ref="sourceRef" />
 *
 *    <!-- MyComponent will be teleported here when isActive is true -->
 *    <TargetPortal v-if="isActive" />
 *  </div>
 * </template>
 * ```
 */
export function useTeleport() {
  let isDisposed = false

  /** Source ref */
  const sourceRef = ref()

  /** Parent of source element */
  let sourceParent: HTMLElement | undefined

  function getSourceNode() {
    const el = unrefElement<HTMLElement>(sourceRef)

    if (!el)
      throw new Error('Could not get source element')

    return el
  }

  const Portal = defineComponent<{
    tag?: string
  }>((props) => {
    return () => h(props.tag || 'div', {

      onVnodeMounted(vnode) {
        if (isDisposed)
          return

        const el = vnode.el as HTMLElement
        const sourceNode = getSourceNode()

        // Assign parent element
        if (!sourceParent && parent)
          sourceParent = sourceNode.parentElement!

        el.appendChild(sourceNode)
      },

      onVnodeBeforeUnmount() {
        if (isDisposed)
          return

        const sourceNode = getSourceNode()

        if (!sourceParent)
          throw new Error('sourceParent is not defined. Make sure source element has a parent')

        sourceParent.appendChild(sourceNode)
      }
    })
  })

  onBeforeUnmount(() => {
    isDisposed = true
  })

  return [sourceRef, Portal] as const
}
